import Modal_general from '@/component/modal/modal_general/modal_general';
import { x_chat, x_chat_id, x_chat_user_unique } from '@/declaration/type/x_chat';
import { x_lang_selector } from '@/declaration/type/x_lang_selector';
import { x_login } from '@/declaration/type/x_login';
import { x_login_method } from '@/declaration/type/x_login_method';
import { x_nf_panel } from '@/declaration/type/x_nf_panel';
import { x_pay } from '@/declaration/type/x_pay';
import { x_signer_set_password } from '@/declaration/type/x_signer_set_password';
import { x_signup } from '@/declaration/type/x_signup';
import { x_signup_method } from '@/declaration/type/x_signup_method';
import useQueryTo from '@/hook/router/useQueryTo';
import { loading } from '@/utility/dynamic/loading';
import dynamic from 'next/dynamic';
import { useSearchParams } from 'next/navigation';
import React, { FC, HTMLAttributes, memo, useCallback, useEffect, useState } from 'react';

const Form_language_selector = dynamic(() => import('@/component/form/form_language_selector/form_language_selector'), {
  loading,
});
const Form_login = dynamic(() => import('@/component/form/auth/form_login/form_login'), { loading });
const Form_signup = dynamic(() => import('@/component/form/auth/form_signup/form_signup'), { loading });
const Form_signer_set_password = dynamic(
  () => import('@/component/form/form_signer_change_password/form_signer_change_password'),
  {
    loading,
  },
);
const Pay = dynamic(() => import('@/component/finance/form/form_pay/form_pay'), { loading });
const Nf_panel = dynamic(() => import('../nf/nf_panel/nf_panel'), { loading });
const Chat_loader = dynamic(() => import('@/component/im/chat/chat_loader/chat_loader'), { loading });

export interface I_Modal_control extends HTMLAttributes<HTMLDivElement> {}

const Modal_control: FC<I_Modal_control> = memo<I_Modal_control>(() => {
  const [show_lang_selector, set_show_lang_selector] = useState(false);
  const [show_login, set_show_login] = useState(false);
  const [show_signup, set_show_signup] = useState(false);
  const [show_signer_set_password, set_show_signer_set_password] = useState(false);
  const [show_pay, set_show_pay] = useState(false);
  const [show_nf_panel, set_show_nf_panel] = useState(false);
  const [show_chat, set_show_chat] = useState(false);
  const query = useSearchParams();
  const { subtract } = useQueryTo();

  const on_close_lang_selector = useCallback((): void => {
    subtract([x_lang_selector]);
  }, [subtract]);

  const on_close_login = useCallback(() => {
    subtract([x_login, x_login_method]);
  }, [subtract]);

  const on_close_signup = useCallback(() => {
    subtract([x_signup, x_signup_method]);
  }, [subtract]);

  const on_close_signer_set_password = useCallback(() => {
    subtract([x_signer_set_password]);
  }, [subtract]);

  const on_close_pay = useCallback((): void => {
    subtract([x_pay, 'x_pay_order']);
  }, [subtract]);

  const on_close_nf_panel = useCallback(() => {
    subtract([x_nf_panel]);
  }, [subtract]);

  const on_close_chat = useCallback(() => {
    subtract([x_chat, x_chat_id, x_chat_user_unique]);
  }, [subtract]);

  useEffect(() => {
    set_show_lang_selector(!!query.get(x_lang_selector));
    set_show_login(!!query.get(x_login));
    set_show_signup(!!query.get(x_signup));
    set_show_signer_set_password(!!query.get(x_signer_set_password));
    set_show_pay(!!query.get(x_pay) && !!query.get('x_pay_order'));
    set_show_nf_panel(!!query.get(x_nf_panel));
    set_show_chat(!!query.get(x_chat));
  }, [query]);

  return (
    <>
      <Modal_general open={show_lang_selector} onClose={on_close_lang_selector}>
        <Form_language_selector />
      </Modal_general>
      <Modal_general open={show_login} onClose={on_close_login}>
        <Form_login on_success={on_close_login} />
      </Modal_general>
      <Modal_general open={show_signup} onClose={on_close_signup}>
        <Form_signup on_success={on_close_signup} />
      </Modal_general>
      <Modal_general open={show_signer_set_password} onClose={on_close_signer_set_password}>
        <Form_signer_set_password on_success={on_close_signer_set_password} first_time />
      </Modal_general>
      <Modal_general open={show_pay} onClose={on_close_pay}>
        {show_pay ? (
          <Pay
            order={JSON.parse(query.get('x_pay_order') || 'null')}
            title={query.get('x_pay_title') || undefined}
            on_paid={on_close_pay}
            onClose={on_close_pay}
          />
        ) : (
          <div>Invalid order args</div>
        )}
      </Modal_general>
      <Modal_general open={show_nf_panel} onClose={on_close_nf_panel}>
        <Nf_panel on_done={on_close_nf_panel} on_read_all={on_close_nf_panel} />
      </Modal_general>
      <Modal_general open={show_chat} onClose={on_close_chat}>
        <Chat_loader chat_id={query.get(x_chat_id)} user_unique={query.get(x_chat_user_unique)} />
      </Modal_general>
    </>
  );
});

export default Modal_control;
